// todo consider switching to a proper util if necessary
$divisor: 4;
$divisor-xs: 4;

$spaces: (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
);
$sides: (top, right, bottom, left);

// Bootstrap utility classes:
// p-0: [padding: 0rem]
// ml-0: [margin-left: 0rem]

// pl-1 : [ padding-left: 0.25rem ]
// mr-2 : [ margin-right: 0.5rem ]
// pt-3 : [ padding-top: 0.75rem ]
// mb-4 : [ margin-bottom: 1rem ]

// pl-5 : [ padding-left: 1.25rem ]
// mr-6 : [ margin-right: 1.5rem ]
// pt-7 : [ padding-top: 1.75rem ]
// mb-8 : [ margin-bottom: 2rem ]

@media screen and (min-width: 355px) {
  @each $space in $spaces {
    .m-#{$space} {
      margin: #{$space/$divisor}rem;
    }
    .p-#{$space} {
      padding: #{$space/$divisor}rem;
    }
    .mx-#{$space} {
      margin-left: #{$space/$divisor}rem;
      margin-right: #{$space/$divisor}rem;
    }
    .px-#{$space} {
      padding-left: #{$space/$divisor}rem;
      padding-right: #{$space/$divisor}rem;
    }
    .my-#{$space} {
      margin-top: #{$space/$divisor}rem;
      margin-bottom: #{$space/$divisor}rem;
    }
    .py-#{$space} {
      padding-top: #{$space/$divisor}rem;
      padding-bottom: #{$space/$divisor}rem;
    }
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space/$divisor}rem !important;
      }
      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space/$divisor}rem !important;
      }
    }
  }

  .m-0_5 {
    margin: #{1/ ($divisor * 2)} rem;
  }
  .p-0_5 {
    padding: #{1/ ($divisor * 2)}rem;
  }
  .mx-0_5 {
    margin-left: #{1/ ($divisor * 2)}rem;
    margin-right: #{1/ ($divisor * 2)}rem;
  }
  .px-0_5 {
    padding-left: #{1/ ($divisor * 2)}rem;
    padding-right: #{1/ ($divisor * 2)}rem;
  }
  .my-0_5 {
    margin-top: #{1/ ($divisor * 2)}rem;
    margin-bottom: #{1/ ($divisor * 2)}rem;
  }
  .py-0_5 {
    padding-top: #{1/ ($divisor * 2)}rem;
    padding-bottom: #{1/ ($divisor * 2)}rem;
  }
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-0_5 {
      margin-#{$side}: #{1/ ($divisor * 2)}rem !important;
    }
    .p#{str-slice($side, 0, 1)}-0_5 {
      padding-#{$side}: #{1/ ($divisor * 2)}rem !important;
    }
  }
}

@media screen and (max-width: 354px) {
  $divisor: 5.5;

  @each $space in $spaces {
    .m-#{$space} {
      margin: #{$space/$divisor}rem;
    }
    .p-#{$space} {
      padding: #{$space/$divisor}rem;
    }
    .mx-#{$space} {
      margin-left: #{$space/$divisor}rem;
      margin-right: #{$space/$divisor}rem;
    }
    .px-#{$space} {
      padding-left: #{$space/$divisor}rem;
      padding-right: #{$space/$divisor}rem;
    }
    .my-#{$space} {
      margin-top: #{$space/$divisor}rem;
      margin-bottom: #{$space/$divisor}rem;
    }
    .py-#{$space} {
      padding-top: #{$space/$divisor}rem;
      padding-bottom: #{$space/$divisor}rem;
    }
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space/$divisor}rem !important;
      }
      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space/$divisor}rem !important;
      }
    }
  }

  .m-0_5 {
    margin: #{1/ ($divisor * 2)} rem;
  }
  .p-0_5 {
    padding: #{1/ ($divisor * 2)}rem;
  }
  .mx-0_5 {
    margin-left: #{1/ ($divisor * 2)}rem;
    margin-right: #{1/ ($divisor * 2)}rem;
  }
  .px-0_5 {
    padding-left: #{1/ ($divisor * 2)}rem;
    padding-right: #{1/ ($divisor * 2)}rem;
  }
  .my-0_5 {
    margin-top: #{1/ ($divisor * 2)}rem;
    margin-bottom: #{1/ ($divisor * 2)}rem;
  }
  .py-0_5 {
    padding-top: #{1/ ($divisor * 2)}rem;
    padding-bottom: #{1/ ($divisor * 2)}rem;
  }
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-0_5 {
      margin-#{$side}: #{1/ ($divisor * 2)}rem !important;
    }
    .p#{str-slice($side, 0, 1)}-0_5 {
      padding-#{$side}: #{1/ ($divisor * 2)}rem !important;
    }
  }
}
.h-100 {
  height: 100% !important;
}

$percents: (10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 25, 75);

@each $percent in $percents {
  .w-#{$percent} {
    width: #{$percent}% !important;
  }
}

.w-66 {
  width: calc(100% / 1.5) !important;
}

.w-33 {
  width: calc(100% / 3) !important;
}

.w-15 {
  width: 15% !important;
}

.w-25 {
  width: 25% !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.inline-flex-middle {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}
