// same as ./antd.customize.less, but for scss
$primary-color: #00b2e3;
// $primary-color: #4a3d7c;
$secondary-color: #f39323;

.typography-title {
  font-weight: 500 !important;
  font-size: 24px;
  color: #4b4c68;
}

.typography-page-title {
  font-weight: 500 !important;
  color: #4b4c68;
}

.typography-subtitle {
  font-weight: 300 !important;
  font-size: 20px;
}

.typography-text {
  font-size: 16px !important;
}

.force-button-style {
  padding: 6.4px 15px !important;
  outline: unset !important;
  background: $primary-color !important;
  border: unset !important;
  border-color: $primary-color !important;
  border-radius: 2px !important;
}

.force-input-style {
  height: 40.15px !important;
  display: inline-flex !important;
  flex-direction: column;
  justify-content: center;
}

.select-has-image-prefix {
  .ant-select-selector {
    .ant-select-selection-search {
      input {
        padding-left: 18px;
      }
    }
  }
}

.ant-select-selection-placeholder {
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.25);
}

.ant-input-suffix {
  .ant-input-password-icon {
    color: $primary-color;
  }
}

.site-form-item-icon {
  color: $primary-color;
}

.antd-select-prefix-image {
  padding-bottom: 3px;
}

.social-login {
  &-text {
    margin-top: 0.8rem;
  }
  &-icons {
    margin-top: 0.8rem;
  }
}

.primary {
  color: $primary-color !important;
}

.secondary {
  color: $secondary-color !important;
}

.text-secondary {
  color: #4b4c68 !important;
}

.text-primary {
  color: #005ba7 !important;
}

.ant-spin-dot > i {
  background-color: $secondary-color !important;
}

.success {
  color: #52c41a;
}

.error {
  color: #ff4d4f;
}

.warning {
  color: #faad14;
}

.arrow-back {
  font-size: 1rem;
  padding-left: 0.8rem;
  position: absolute;
}

.selected-plan {
  // background-color: #939DEC;
  border: 1px solid $primary-color;
  color: $primary-color;
}

.spend-limits-top-row {
  .ant-col {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    border-bottom: unset;
    border-radius: 4px 4px 0px 0px;
  }
}
.spend-limits-bottom-row {
  .ant-col {
    padding-bottom: 1rem;
    border-top: unset;

    border-radius: 0px 0px 4px 4px;
  }
}
.spend-limits-row {
  .ant-col {
    border-top: unset;
    border-bottom: unset;

    padding-bottom: 1rem;
  }
}

.custom-form .ant-form-item {
  margin-bottom: 8px !important;
  .ant-form-item-label {
    padding: 0 0 4px;
  }
}

/* ---------------------------------------------------------------------------------- */
/* AntD Upload picture wall
/* ---------------------------------------------------------------------------------- */
.ant-upload-picture-card-wrapper {
  width: unset !important;
  .ant-upload-list-item-actions {
    width: 100%;
    height: 100%;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;

      .anticon {
        display: none;
      }
    }

    .ant-upload-list-item-card-actions-btn {
      display: none;
    }
  }
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  left: 0;
}
/* ---------------------------------------------------------------------------------- */
/* Account List - Card
/* ---------------------------------------------------------------------------------- */
.account-card.ant-card {
  border-color: $primary-color !important;
  border-radius: 8px;

  .ant-card-head {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $primary-color;
    color: #fff;
  }

  div {
    border-color: $primary-color !important;
  }
}

/* ---------------------------------------------------------------------------------- */
/* Small screen handling
/* ---------------------------------------------------------------------------------- */
@media screen and (max-width: 350px) {
  .ant-form-item {
    margin-bottom: 16px !important;

    &.collapsible.hide {
      display: block !important;
      height: 0px !important;
      margin: 0px !important;
      padding: 0px !important;
      border: unset !important;
      opacity: 0 !important;
    }
  }

  .app {
    .typography-title {
      font-size: 22px !important;
    }

    .typography-subtitle {
      font-size: 18px !important;
    }
  }
}

/* ---------------------------------------------------------------------------------- */
/* Profile Menu
/* ---------------------------------------------------------------------------------- */
.profile-menu.ant-menu-vertical {
  border-right: unset;
  font-size: 18px;
  padding: 0 16px;

  .ant-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  svg {
    margin-right: 0.9rem;
  }
}

/* ---------------------------------------------------------------------------------- */
/* Profile Menu - Address animation
/* ---------------------------------------------------------------------------------- */
.ant-form-item,
.ant-row,
.ant-btn {
  &.collapsible {
    transition: padding height margin opacity ease-in-out 0.7s;
  }

  &.collapsible.hide {
    display: block !important;
    height: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
    border: unset !important;
    opacity: 0 !important;
  }
}

/* ---------------------------------------------------------------------------------- */
/* Bottom Menu Bar 
/* ---------------------------------------------------------------------------------- */
.menu-bar-margin {
  margin-bottom: 48px;
}
.menu-bar-container {
  position: fixed;
  bottom: 0;
}

.menu-bar.ant-menu-horizontal {
  .ant-menu-item {
    width: calc(100% / 3);
    text-align: center;
    font-size: 1.4em;
    border-top: 2px solid transparent;
    border-bottom: unset;
    color: #a39db6;

    &:hover {
      border-bottom: unset;
      border-top-color: $primary-color;
      color: $primary-color;
    }
  }

  .ant-menu-item-selected {
    border-bottom: unset;
    border-top-color: $primary-color;
    color: $primary-color;
  }
}

/* Chrome, Safari, Edge, Opera */
.disable-number-spin-button input::-webkit-outer-spin-button,
.disable-number-spin-button input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  box-shadow: none;
}

/* Firefox */
.disable-number-spin-button input[type="number"] {
  -moz-appearance: textfield;
  box-shadow: none;
}
